<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title v-if="!permissionData.length">No Permissions Granted</b-card-sub-title>
      <!-- <b-button variant="primary" @click="showPermissions"> -->
      <b-button variant="primary" @click="$emit('open-permissions-modal')">
        Update
      </b-button>
    </b-card-header>
    <b-table
      striped
      responsive
      :items="permissionData"
      class="mb-0"
    >
      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardHeader, BButton, BCardTitle, BCardSubTitle, BFormCheckbox, BModal, BFormGroup
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardHeader, BButton, BCardTitle, BCardSubTitle, BFormCheckbox, BModal, BFormGroup
  },
  props: {
    permissions: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const permissionData = props.permissions

    return {
      permissionData,
    }
  },
}
</script>

<style>

</style>
