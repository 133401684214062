<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="teacherData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No teacher found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-teachers-list' }">
          Teachers List
        </b-link>
        for other teachers.
      </div>
    </b-alert>

    <template v-if="teacherData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <teacher-info-card
            :user-data="teacherData"
            @open-add-to-school-modal="addToSchoolModal = true"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <teacher-timeline-card :activity="teacherData.activity" />
        </b-col>
        <!-- <b-col cols="12" lg="6">
          <teacher-permissions-card
            :permissions="permissionData"
            @open-permissions-modal="updatePermissionsModal = true"
          />
        </b-col> -->
      </b-row>
    </template>

    <!-- Add Teacher To School Modal -->
    <b-modal
      v-model="addToSchoolModal"
      hide-footer
      centered
      size="sm"
      title="Add Teacher to School"
    >
      <b-form-group label="Select School" label-for="selectSchool">
        <div>
          <b-form-select v-model="addToSchoolId" :options="schools">
          </b-form-select>
        </div>
      </b-form-group>

      <b-button variant="outline-primary" @click="addTeacherToSchool()">
        Submit
      </b-button>
    </b-modal>

    <!-- Update Teacher Permissions -->
    <b-modal
      v-if="teacherData != null"
      v-model="updatePermissionsModal"
      hide-footer
      centered
      size="lg"
      :title="teacherData.first_name + '\'s Permissions'"
    >
      <b-form-group label="Update the teacher's permissions" label-for="updatePermissions">
        <div>
          <!-- <b-table
            striped
            responsive
            :items="permissionData"
            class="mb-0"
          >
            <template #cell(module)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox
                :checked="data.value"
              />
            </template>
          </b-table> -->
        </div>
      </b-form-group>

      <b-button variant="outline-primary" @click="updatePermissions()">
        Submit
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BModal,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BFormCheckbox,
} from "bootstrap-vue";
import userStoreModule from "../userStoreModule";
import useTeachersList from "./useTeachersList";
import TeacherInfoCard from "./TeacherInfoCard.vue";
import TeacherTimelineCard from "./TeacherTimelineCard.vue";
import TeacherPermissionsCard from "./TeacherPermissionsCard.vue";
import Pluralize from 'pluralize';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BFormCheckbox,

    // Local Components
    TeacherInfoCard,
    TeacherTimelineCard,
    TeacherPermissionsCard,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const permissionData = ref([])

    store
      .dispatch("app-user/fetchTeacher", { id: router.currentRoute.params.id })
      .then((response) => {
        teacherData.value = response.data;
        // console.log(response.data.permissions)
        let modules = []
        let allPermissions = []
        teacherData.value.permissions.forEach(permission => {
          if(permission.name != 'content view') {
            allPermissions.push(permission.name)
            modules.push(permission.name.split(" ")[0])
          }
        });

        // Remove duplicates
        let uniqueModules = [...new Set(modules)]

        uniqueModules.forEach((module) => {
          const str = module;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          permissionData.value.push(
            {
              module: Pluralize(str2),
              read: allPermissions.includes(module + ' read') ? true : false,
              create: allPermissions.includes(module + ' create') ? true : false,
              edit: allPermissions.includes(module + ' edit') ? true : false,
              delete: allPermissions.includes(module + ' delete') ? true : false
            }
          )
        })
      })
      .catch(() => {
        teacherData.value = undefined;
      });

    const schools = [];
    const fetchSchools = () => {
      store
        .dispatch("app-user/fetchAllSchools")
        .then((response) => {
          response.data.data.forEach((school) => {
            schools.push({ value: school.id, text: school.name });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchSchools();

    const updatePermissions = (event) => {
      console.log(event)
    }

    const {
      teacherData,

      addToSchoolModal,
      addToSchoolId,
      addToSchoolTeacherId,
      addTeacherToSchool,

      updatePermissionsModal,
      updatedPermissions,
      submitUpdatedPermissions,
    } = useTeachersList();

    return {
      schools,
      teacherData,
      permissionData,

      addToSchoolModal,
      addToSchoolId,
      addToSchoolTeacherId,
      addTeacherToSchool,

      updatePermissionsModal,
      updatedPermissions,
      submitUpdatedPermissions,

      updatePermissions,
    };
  },
};
</script>

<style></style>
